import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';  // スタイルもインポート
import Swal from 'sweetalert2';
window.Swal = Swal; // グローバルに使えるようにする
//import Sortable from 'sortablejs';


const addImageButton = document.querySelector('#js-add-image');
if (addImageButton) {
    document.querySelector('#js-add-image').addEventListener('click', function (e) {
        e.preventDefault();  // formの送信を阻止

        Swal.fire({
            title: '項目名を入力してください',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'OK',
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                const labelName = result.value;
                addUploader(labelName);
            }
        });
    });

}


Dropzone.autoDiscover = false;

// Dropzoneの初期化関数
function initializeDropzone(element) {


    new Dropzone(element, {
        url: "/drop-image-up",
        maxFiles: 1,
        maxFilesize: 50, // MB
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        dictRemoveFile: '削除',
        headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        dictRemoveFileConfirmation: 'ファイルを削除してよろしいですか？',
        dictDefaultMessage: '画像を選択してください',
        resizeWidth: 800, // リサイズ後の最大幅
        resizeHeight: 600, // リサイズ後の最大高さ
        resizeMethod: 'contain', // リサイズ方法
        resizeQuality: 0.6, // リサイズの品質
        sending: function (file, xhr, formData) {
            // ドロップゾーン要素からdata-idの値を取得
            const dataId = this.element.getAttribute('data-id');
            const dataLabel = this.element.getAttribute('data-label');
            // リクエストにdata-idの値を追加
            formData.append('dataId', dataId);
            formData.append('dataLabel', dataLabel);
        },
        init: function () {
            this.on('addedfile', function (file) {
                console.log('File added:', file);
            });

            this.on('success', function (file, response) {
                console.log('Upload success:', file, response);
                // 追加されたDropzoneの場合はリロードしない
                if (!this.element.hasAttribute('data-added')) {
                    location.reload();
                } else {
                    // 追加されたDropzoneであれば、プレビュー表示などの処理
                    const previewContainer = document.createElement('div');
                    // previewContainer.classList.add('image-preview');
                    // const imageElement = document.createElement('img');
                    // imageElement.src = response.url;
                    // previewContainer.appendChild(imageElement);
                    // this.element.appendChild(previewContainer);
                }
            });

            this.on('error', function (file, errorMessage, xhr) {
                console.log('Upload error:', errorMessage);
            });
        },
        success: function (file, response) {
            // サーバーレスポンスから必要な情報を取得
            const imageUrl = response.url; // 'url' キーを使用
            const imageId = response.id;
            // 'path' キーも取得できるが、今の例では使用していません。

            // 隠しフィールドを作成してフォームに追加
            const inputUrl = document.createElement("input");
            inputUrl.type = "hidden";
            inputUrl.name = "uploadedImageUrls[]"; // 配列として送信するための名前
            inputUrl.value = imageUrl;
            // formElement.appendChild(inputUrl);
        }
    });
}

// ページ読み込み時にすでに存在するDropzoneエレメントを初期化
document.addEventListener('DOMContentLoaded', function () {
    const dropzones = document.querySelectorAll('.js-dropzone');
    dropzones.forEach((el) => {
        initializeDropzone(el);
    });
});



function generateRandomTwoDigit() {
    return Math.floor(Math.random() * 90 + 10); // 10から99のランダムな数
}

function addUploader(labelName) {
    const container = document.querySelector('.form-images__add-container');
    const addArea = document.querySelector('.form-images__add'); // 追加領域を取得

    const randomId = generateRandomTwoDigit();

    const uploaderDiv = document.createElement('div');
    uploaderDiv.classList.add('c-photo-uploader');

    const label = document.createElement('label');
    label.classList.add('c-photo-uploader__label');
    label.textContent = labelName;
    uploaderDiv.appendChild(label);

    const dropzoneDiv = document.createElement('div');
    dropzoneDiv.classList.add('dropzone', 'js-dropzone');
    dropzoneDiv.id = 'dropzone-add-' + randomId; // ランダムなIDを使用
    uploaderDiv.appendChild(dropzoneDiv);

    // 削除ボタンの追加
    const deleteButton = document.createElement('button');
    deleteButton.textContent = '削除';
    deleteButton.classList.add('c-photo-uploader__delete-btn');
    deleteButton.onclick = function () {
        // 削除ボタンがクリックされたときの処理
        container.removeChild(uploaderDiv);
    };
    uploaderDiv.appendChild(deleteButton);

    // ランダムなIDをdata-id属性に設定
    dropzoneDiv.setAttribute('data-id', 'add-' + randomId);
    dropzoneDiv.setAttribute('data-label', labelName);
    dropzoneDiv.setAttribute('data-added', 'true');

    container.appendChild(uploaderDiv);
    initializeDropzone(dropzoneDiv);

    // getComputedStyle() を使用して実際のスタイルを取得
    const addAreaStyle = window.getComputedStyle(addArea);

    // アップローダーが追加されたら追加領域を表示
    if (addAreaStyle.display === 'none') {
        addArea.style.display = 'block';
    }
}
